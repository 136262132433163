<template>
  <v-app>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | ${siteName}` : siteName }}</template>
    </metainfo>
    <router-view/>
    <notifications group="report" :duration="15000">
      <template v-slot:body="{item}">
        <div :class="['vue-notification-template vue-notification', item.type]">
          <div class="notification-title">{{ item.title }}</div>
          <div class="notification-content" v-if="item.type === 'success'">
            Отчет успешно сформирован<br>
            <span style="cursor: pointer; font-weight: 800" @click="downloadReport(item)">Сохранить</span>
          </div>
          <div class="notification-content" v-else>{{ item.text }}</div>
        </div>
      </template>
    </notifications>
    <notifications />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {NotificationItem, Notifications} from "@kyvg/vue3-notification";
import {mapGetters} from "vuex";
import { useMeta } from 'vue-meta';
import {Layout} from "@/helpers/enums/layout";
import {DownloadReportRequest} from "@/store/modules/common/models";

export default defineComponent({
  setup () {
    useMeta({
      title: '',
    })

    return {Layout};
  },
  name: 'App',
  components: {Notifications},
  computed: {
    ...mapGetters('auth', [
        'userData',
    ]),
    siteName(): string {
      return process.env.VUE_APP_SITE_NAME ? process.env.VUE_APP_SITE_NAME : '';
    },
    layout(): Layout {
      if (this.windowWidth < 768) {
        return Layout.Mobile;
      } else if (this.windowWidth < 1440) {
        return Layout.Tablet;
      } else {
        return Layout.Desktop;
      }
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth,
    }
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  mounted() {
    if (this.userData === null && location.pathname !== '/login') {
      this.$router.push({ name: 'login' });
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    downloadReport(item: NotificationItem) {
      this.$store.dispatch('common/downloadReport', {
        tempFile: (item.data as any)?.file?.tempFile,
        fileName: (item.data as any)?.file?.fileName,
      } as DownloadReportRequest);
    }
  },
  watch: {
    userData() {
      if (this.userData === null) {
        this.$router.push({ name: 'login' });
      }
    },
  }
})
</script>
