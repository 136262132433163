import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    GetRestrictionsRequest,
    Restriction, RestrictionProduct, RestrictionProductCategory,
    RestrictionType,
    SaveRestrictionsRequest
} from "@/store/modules/restriction/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {
        types: [] as RestrictionType[],
        products: [] as RestrictionProduct[],
        categories: [] as RestrictionProductCategory[],
        list: [] as Restriction[],
        map: {} as Record<string, Restriction>,
    },
    getters: {
        restrictionsByOilCard(state) {
            return function (oilCardId: string): Restriction[] {
                return state.map[oilCardId] ? state.map[oilCardId] : [];
            }
        },
        restrictionTypes(state): RestrictionType[] {
            return state.types;
        },
        restrictionProducts(state): RestrictionProduct[] {
            return state.products;
        },
        restrictionProductsByCategory(state) {
            return function (categoryId: string): RestrictionProduct[] {
                return state.products.filter((product: RestrictionProduct): boolean => product.categoryId === categoryId);
            }
        },
        restrictionProductCategories(state): RestrictionProductCategory[] {
            return state.categories;
        },
    },
    mutations: {
        setTypes(state, data) {
            state.types = data;
        },
        setProducts(state, data) {
            state.products = data;
        },
        setProductCategories(state, data) {
            state.categories = data;
        },
        setMap(state, {oilCardId, data}) {
            state.map[oilCardId] = data;
        },
        updateMap(state, {oilCardId, data}) {
            const listToUpdate: Restriction[] = data;
            const currentList: Restriction[] = state.map[oilCardId] ? state.map[oilCardId] : [];
            listToUpdate.forEach(updatedRestriction => {
                const index = currentList.findIndex(restriction => restriction.id === updatedRestriction.id);
                if (-1 !== index) {
                    currentList.splice(index, 1, updatedRestriction);
                }
            });
            currentList.forEach((restriction, index) => {
                const updatedIndex = listToUpdate.findIndex(updatedRestriction => updatedRestriction.id === restriction.id);
                if (-1 === updatedIndex && restriction.id && restriction.isDeleted && !restriction.isSetInOilCompany) {
                    currentList.splice(index, 1);
                }
            });
        },
    },
    actions: <ActionTree<any, any>>{
        async fetchList(ctx, request: GetRestrictionsRequest): Promise<Restriction[]> {
            const response  = await api.get<Restriction[]>('api/lk/v1/restrictions', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetRestrictionsRequest): Promise<Restriction[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async getListWithSave(ctx, request: GetRestrictionsRequest): Promise<Restriction[]> {
            const data =  await ctx.dispatch('fetchList', request);
            const oilCardId = request.oilCardId;
            ctx.commit('setMap', {oilCardId, data});
            return data;
        },
        async updateList(ctx, request: GetRestrictionsRequest): Promise<Restriction[]> {
            const data = await ctx.dispatch('fetchList', request);
            const oilCardId = request.oilCardId;
            ctx.commit('updateMap', {oilCardId, data});
            return data;
        },
        async getTypes(ctx): Promise<RestrictionType[]> {
            const response  = await api.get<RestrictionType[]>('api/lk/v1/restriction_types');
            ctx.commit('setTypes', response.data);

            return response.data;
        },
        async getProductCategories(ctx): Promise<RestrictionProductCategory[]> {
            const response  = await api.get<RestrictionProductCategory[]>('api/lk/v1/restriction_product_categories');
            ctx.commit('setProductCategories', response.data);

            return response.data;
        },
        async getProducts(ctx): Promise<RestrictionProduct[]> {
            const response  = await api.get<RestrictionProduct[]>('api/lk/v1/restriction_products');
            ctx.commit('setProducts', response.data);

            return response.data;
        },
        async saveRestrictions(ctx, request: SaveRestrictionsRequest) {
            await api.post('api/lk/v1/restrictions', request);
        }
    }
};